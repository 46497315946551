import { Injectable, inject } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { BackendService, MethodType } from './backend.service';
import {
    Company,
    DateRange,
    ER,
    Filters,
    Mention,
    PaginatedServerResponse,
    PortfolioFilters,
    ServerResponse,
    Standard,
    StandardKey,
    TaxonomyItem,
    FrameworkResults,
    TimeAggregation,
    SentimentTimeAggr,
    Search,
} from '../types/types';
import { Observable, catchError, forkJoin, iif, map, of, switchMap, tap } from 'rxjs';
import { formatDate } from '../utility/utility';
import { UserService } from './user.service';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { SearchConditionService } from './search-condition.service';
import { QueryOptionsService } from './query-options.service';

interface SearchParameters {
    query?: ER.Query;
    page?: number;
    count?: number;
}
type DataType = keyof typeof Standard | 'ALL';

interface SearchConfig {
    userData?: boolean;
    page?: number;
    count?: number;
    taxonomyList?: TaxonomyItem[];
    includeAllFromTaxonomy?: boolean;
    dataType: DataType;
}

interface TimelineConfig {
    userData?: boolean;
    taxonomyItem?: TaxonomyItem;
    dataType: DataType;
}
interface SentimentConfig {
    userData?: boolean;
}
interface PaiConfig {
    userData?: boolean;
    page?: number;
    count?: number;
}

interface PostRequestConfiguration {
    headers?: HttpHeaders | { [header: string]: string | string[] };
    /**
     * You can specify a path which'll be extracted from the result
     */
    extractPath?: string;
    /**
     * Used in conjunction with the `extractPath`. This defines a default value in case the extractPath is unavailable or empty.
     */
    defaultValue?: unknown;
    /**
     * Set to true if you want to stringify and parse content as a form request. Works the same as $.post.
     */
    sendAsForm?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private backendService = inject(BackendService);
    private userService = inject(UserService);
    private queryOptions = inject(QueryOptionsService);
    private searchCondition = inject(SearchConditionService);

    public standardMap: Record<Standard, StandardKey> = {
        [Standard.ESG]: 'esg',
        [Standard.SDG]: 'sdg',
        [Standard.SASB]: 'sasb',
    };
    public error?: string;
    private eventTypeUrl = '/eventType/mention/getMentions';
    private eventTypeUserUrl = '/eventTypeUser/mention/getMentions';
    private data: Map<DataType, PaginatedServerResponse<Mention[]>> = new Map();
    private timelines: Map<DataType, FrameworkResults<TimeAggregation>[]> = new Map();
    private sentiment?: ServerResponse<SentimentTimeAggr[]>;
    private paiData?: PaginatedServerResponse<Mention[]>;
    private conditions: ER.QueryOptionsArgument = {};

    private queryOptionsForFiltering: ER.QueryOptionsArgument = {};
    private _cachedStorage: Map<string, unknown> = new Map();
    private readonly _loadingTrackers: Map<string, boolean> = new Map();

    constructor() {
        this._loadingTrackers.set('ALL', false);
        this._loadingTrackers.set('ESG', false);
        this._loadingTrackers.set('SDG', false);
        this._loadingTrackers.set('SASB', false);
    }

    public isCurrentlyLoading(dataType: DataType): boolean {
        return this._loadingTrackers.get(dataType) || false;
    }

    public getMentionsResponse(standard: Standard): PaginatedServerResponse<Mention[]> {
        return this.data.get(this.standardMap[standard].toUpperCase() as DataType) as PaginatedServerResponse<
            Mention[]
        >;
    }
    public getSentimentResponse() {
        return this.sentiment;
    }
    public getPaiResponse() {
        return this.paiData;
    }

    public getResults(standard: Standard) {
        return this.timelines.get(
            this.standardMap[standard].toUpperCase() as DataType,
        ) as FrameworkResults<TimeAggregation>[];
    }

    public makeQuery(companies: string[], filters: PortfolioFilters) {
        const dateObject = {
            dateStart: formatDate(filters.startDate),
            dateEnd: formatDate(filters.endDate),
        };
        const companiesArray = companies.map((c) => ({ conceptUri: c }));
        return {
            $query: {
                $and: [{ $or: companiesArray }, dateObject],
            },
        };
    }

    public getTaxonomyListFromLocalStorage(standard: Standard): TaxonomyItem[] {
        return this.getFromLocalStorage(`TaxonomyList-${standard}`, []);
    }

    public getTimelineData$(
        query: ER.Query,
        config: TimelineConfig,
    ): Observable<ServerResponse<TimeAggregation[]>> {
        const { userData = false, taxonomyItem, dataType = 'ALL' } = config;
        const params = {
            query: query,
            mentionsConceptSyn: ['isin', 'ticker'],
            resultType: 'timeAggr',
        };
        return this.post$<ServerResponse<TimeAggregation[]>>(
            userData ? this.eventTypeUserUrl : this.eventTypeUrl,
            params,
            { extractPath: 'timeAggr' },
        ).pipe(
            tap((response) => {
                if (taxonomyItem) {
                    const timelines = this.timelines.get(dataType) || [];
                    this.timelines.set(dataType, [
                        ...timelines,
                        { ...taxonomyItem, ...response },
                    ] as FrameworkResults<TimeAggregation>[]);
                }
            }),
        );
    }

    public setToLoading(dataType: DataType) {
        this._loadingTrackers.set(dataType, true);
    }

    public filter$(frameworkItem: TaxonomyItem, config: SearchConfig = { dataType: 'ALL' }) {
        const { userData = false, page = 1, count = 48, dataType } = config;
        this.setToLoading(dataType);
        const standardKey = frameworkItem.uri.split('/')[0].toUpperCase() as unknown as keyof typeof Standard;
        if (!standardKey) {
            return of({
                results: [],
                totalResults: 0,
                count: 0,
                page: 1,
                pages: 1,
                error: 'Invalid standard',
            } as PaginatedServerResponse<Mention[]>);
        } else {
            const filtered = structuredClone(this.queryOptionsForFiltering);
            _.set(filtered, `${standardKey.toLowerCase()}EventTypes`, [frameworkItem]);
            const query = this.queryOptions.build(filtered, {
                sendAllTimeParams: true,
            });
            if (query.error) {
                this._loadingTrackers.set(dataType, false);
                return of({
                    results: [],
                    totalResults: 0,
                    count: 0,
                    page: 1,
                    pages: 1,
                    error: query.error,
                } as PaginatedServerResponse<Mention[]>);
            }
            const params = this.getSearchParams(query, 'mentions', page, count);
            return this.post$<PaginatedServerResponse<Mention[]>>(
                userData ? this.eventTypeUserUrl : this.eventTypeUrl,
                params,
            ).pipe(
                map((response) => {
                    if (_.has(response, 'error')) {
                        return {
                            results: [],
                            totalResults: 0,
                            count: 0,
                            page: 1,
                            pages: 1,
                            error: response.error,
                        } as PaginatedServerResponse<Mention[]>;
                    } else {
                        return _.get(response, 'mentions', {
                            results: [],
                            totalResults: 0,
                            count: 0,
                            page: 1,
                            pages: 1,
                        } as PaginatedServerResponse<Mention[]>);
                    }
                }),
                catchError((error) => {
                    this._loadingTrackers.set(dataType, false);
                    return of({
                        results: [],
                        totalResults: 0,
                        count: 0,
                        page: 1,
                        pages: 1,
                        error,
                    } as PaginatedServerResponse<Mention[]>);
                }),
                tap((response) => {
                    this.data.set(standardKey, response as PaginatedServerResponse<Mention[]>);
                    this._loadingTrackers.set(dataType, false);
                }),
            );
        }
    }
    public getSentimentData$(
        query: ER.Query,
        config: SentimentConfig,
    ): Observable<ServerResponse<SentimentTimeAggr[]>> {
        const { userData = false } = config;
        const params = {
            query: query,
            mentionsConceptSyn: ['isin', 'ticker'],
            resultType: 'sentimentTimeAggr',
        };
        return this.post$<ServerResponse<SentimentTimeAggr[]>>(
            userData ? this.eventTypeUserUrl : this.eventTypeUrl,
            params,
            { extractPath: 'sentimentTimeAggr' },
        ).pipe(
            tap((response) => {
                this.sentiment = response;
            }),
        );
    }
    public fetchSentimentData$(
        queryOptions: ER.QueryOptionsArgument = this.searchCondition.CurrentQuery,
        config: SentimentConfig,
    ) {
        const query = this.queryOptions.build(queryOptions, {
            sendAllTimeParams: true,
        });
        if (query.error) {
            return of({ totalResults: 0, usedResults: 0, results: [] });
        }
        return this.getSentimentData$(query, config);
    }
    public paiEventTypesList = [
        'et/business/products-services',
        'et/business/assets',
        'et/society/safety',
        'et/society/legal',
    ];
    public paiEventTypeQuery(userEventTypes?: Search.Condition[]) {
        const pai = this.paiEventTypesList.flatMap((uri, index, array) => {
            const name = uri.split('/').toSpliced(0, 1).join('→');
            const tag = {
                uri,
                id: uuid(),
                label: name,
                name,
                type: 'other',
            };
            return array.length - 1 !== index
                ? [
                      tag,
                      {
                          id: uuid(),
                          label: 'OR',
                          type: 'operator',
                      },
                  ]
                : [tag];
        });
        if (!userEventTypes || !userEventTypes.length) return pai;
        return [
            { label: '(', type: 'parantheses', id: uuid() },
            ...userEventTypes,
            { label: ')', type: 'parantheses', id: uuid() },
            { id: 'c3', label: 'AND', negate: false, type: 'operator' },
            { label: '(', type: 'parantheses', id: uuid() },
            ...pai,
            { label: ')', type: 'parantheses', id: uuid() },
        ];
    }
    public fetchPaiData$(
        queryOptions: ER.QueryOptionsArgument = this.searchCondition.CurrentQuery,
        config: PaiConfig,
    ): Observable<PaginatedServerResponse<Mention[]>> {
        const { userData = false, page = 1, count = 48 } = config;
        const newQueryOptions = structuredClone(queryOptions);
        newQueryOptions.paiEventTypes = this.paiEventTypesList.map((uri) => {
            const name = uri.split('/').toSpliced(0, 1).join('→');
            return {
                uri,
                id: uuid(),
                label: name,
                name,
                type: 'other',
            };
        });

        const query = this.queryOptions.build(newQueryOptions, {
            sendAllTimeParams: true,
        });
        if (query.error) {
            return of({
                results: [],
                totalResults: 0,
                count: 0,
                page: 1,
                pages: 1,
                error: query.error,
            });
        }
        const params = {
            query,
            includeArticleSentiment: true,
            includeMentionCategories: true,
            includeConceptSynonyms: true,
            mentionsConceptSyn: ['isin', 'ticker'],
            mentionsPage: page,
            mentionsCount: count,
        };
        return this.post$<PaginatedServerResponse<Mention[]>>(
            userData ? this.eventTypeUserUrl : this.eventTypeUrl,
            params,
        ).pipe(
            map((response) => {
                if (_.has(response, 'error')) {
                    return {
                        results: [],
                        totalResults: 0,
                        count: 0,
                        page: 1,
                        pages: 1,
                        error: response.error,
                    };
                } else {
                    return _.get(response, 'mentions', {
                        results: [],
                        totalResults: 0,
                        count: 0,
                        page: 1,
                        pages: 1,
                    });
                }
            }),
            catchError((error) =>
                of({
                    results: [],
                    totalResults: 0,
                    count: 0,
                    page: 1,
                    pages: 1,
                    error,
                }),
            ),
            tap((response) => {
                this.paiData = response;
            }),
        );
    }

    public search$(
        queryOptions: ER.QueryOptionsArgument = this.searchCondition.CurrentQuery,
        config: SearchConfig = { dataType: 'ALL' },
    ): Observable<PaginatedServerResponse<Mention[]>> {
        const { userData = false, page = 1, count = 48, includeAllFromTaxonomy = true, dataType } = config;
        this.setToLoading(dataType);
        const shouldFetchTaxonomyList =
            includeAllFromTaxonomy && !localStorage.getItem(`Standard-${dataType}`) && dataType !== 'ALL';
        const getFromLocalStorage$ = this.getFromLocalStorage$<TaxonomyItem[]>(`Standard-${dataType}`, []);
        return iif(
            () => shouldFetchTaxonomyList,
            this.getTaxonomyList(Standard[dataType as keyof typeof Standard]),
            getFromLocalStorage$,
        ).pipe(
            switchMap(() => {
                if (dataType !== 'ALL') {
                    try {
                        const taxonomyList = JSON.parse(localStorage.getItem(`Standard-${dataType}`) + '');
                        this.searchCondition.setQueryValue(
                            dataType.toLowerCase() + 'EventTypes',
                            taxonomyList,
                        );
                    } catch {}
                }
                this.queryOptionsForFiltering = structuredClone(queryOptions);
                const query = this.queryOptions.build(queryOptions, {
                    sendAllTimeParams: true,
                });
                if (query.error) {
                    this._loadingTrackers.set(dataType, false);
                    return of({
                        results: [],
                        totalResults: 0,
                        count: 0,
                        page: 1,
                        pages: 1,
                        error: query.error,
                    } as PaginatedServerResponse<Mention[]>);
                }
                const params = this.getSearchParams(query, 'mentions', page, count);
                return this.post$<PaginatedServerResponse<Mention[]>>(
                    userData ? this.eventTypeUserUrl : this.eventTypeUrl,
                    params,
                ).pipe(
                    map((response) => {
                        if (_.has(response, 'error')) {
                            return {
                                results: [],
                                totalResults: 0,
                                count: 0,
                                page: 1,
                                pages: 1,
                                error: response.error,
                            } as PaginatedServerResponse<Mention[]>;
                        } else {
                            return _.get(response, 'mentions', {
                                results: [],
                                totalResults: 0,
                                count: 0,
                                page: 1,
                                pages: 1,
                            } as PaginatedServerResponse<Mention[]>);
                        }
                    }),
                    catchError((error) => {
                        this._loadingTrackers.set(dataType, false);
                        return of({
                            results: [],
                            totalResults: 0,
                            count: 0,
                            page: 1,
                            pages: 1,
                            error,
                        } as PaginatedServerResponse<Mention[]>);
                    }),
                    tap((response) => {
                        this.data.set(dataType, response as PaginatedServerResponse<Mention[]>);
                    }),
                    switchMap(() => {
                        this.timelines.set(dataType, []);
                        if (dataType === 'ALL') {
                            return forkJoin([
                                this.getTimelineData$(query, {
                                    userData,
                                    dataType,
                                }),
                            ]);
                        } else {
                            const key = Standard[dataType as keyof typeof Standard];
                            const taxonomyListFromLocalStorage = this.getTaxonomyListFromLocalStorage(key);
                            const isolatedQueryOptions = structuredClone(queryOptions);
                            isolatedQueryOptions.esgEventTypes = [];
                            isolatedQueryOptions.sasbEventTypes = [];
                            isolatedQueryOptions.sdgEventTypes = [];
                            const timelines$ = taxonomyListFromLocalStorage.map((item) => {
                                const eventTypesKey = (dataType.toLowerCase() +
                                    'EventTypes') as keyof ER.QueryOptionsArgument;
                                _.set(isolatedQueryOptions, eventTypesKey, [item]);
                                const isolatedQuery = this.queryOptions.build(isolatedQueryOptions, {
                                    sendAllTimeParams: true,
                                });
                                return this.getTimelineData$(isolatedQuery, {
                                    userData,
                                    taxonomyItem: item,
                                    dataType,
                                });
                            });
                            return forkJoin(timelines$);
                        }
                    }),
                    catchError((error) => {
                        this._loadingTrackers.set(dataType, false);
                        return of({
                            results: [],
                            totalResults: 0,
                            count: 0,
                            page: 1,
                            pages: 1,
                            error: 'Empty results',
                        } as PaginatedServerResponse<Mention[]>);
                    }),
                    map(() => {
                        return (
                            this.data.get(dataType) ||
                            ({
                                results: [],
                                totalResults: 0,
                                count: 0,
                                page: 1,
                                pages: 1,
                                error: 'Empty results',
                            } as PaginatedServerResponse<Mention[]>)
                        );
                    }),
                    tap(() => {
                        this._loadingTrackers.set(dataType, false);
                    }),
                );
            }),
        );
    }

    public getMentions(
        company: Company | Company[],
        filters: Filters,
        dateRange: DateRange,
        standard: Standard,
        frameworkItem: string,
        page: number,
        itemsPerPage: number,
        userData: boolean,
    ) {
        const filtersObject: Record<string, any> = {};
        if (userData) {
            filtersObject['categoryUri'] = 'owner/' + this.userService.getCurrent().email;
        } else {
            filtersObject['startSourceRankPercentile'] = filters.startSourceRankPercentile;
            filtersObject['endSourceRankPercentile'] = filters.endSourceRankPercentile;
        }
        const query: Record<string, any> = {
            $query: {
                $and: [
                    {
                        conceptUri: Array.isArray(company)
                            ? { $or: company.map(({ uri }) => uri) }
                            : company.uri,
                    },
                    {
                        dateStart: formatDate(dateRange.start),
                        dateEnd: formatDate(dateRange.end),
                    },
                ],
            },
            $filter: filtersObject,
        };
        if (frameworkItem !== 'all') {
            switch (standard) {
                case Standard.ESG:
                    query['$query'].$and.push({
                        esgUri: frameworkItem,
                    });
                    break;
                case Standard.SDG:
                    query['$query'].$and.push({
                        sdgUri: frameworkItem,
                    });
                    break;
                case Standard.SASB:
                    query['$query'].$and.push({
                        sasbUri: frameworkItem,
                    });
                    break;
            }
        }
        return this.backendService
            .post$<{ mentions: ServerResponse<Mention[]> }>(
                userData ? this.eventTypeUserUrl : this.eventTypeUrl,
                {
                    query: JSON.stringify(query),
                    mentionsCount: itemsPerPage,
                    mentionsPage: page + 1,
                    // mentionsConceptSyn: ["isin", "ticker"],
                },
            )
            .pipe(
                map((response) => response.body!),
                map((response) => {
                    if (response && !('error' in response)) {
                        return response;
                    }
                    return {
                        mentions: { results: [], pages: 1, totalResults: 0 },
                    };
                }),
                map((response) => {
                    return response.mentions;
                }),
            );
    }
    public getTimeline(
        company: Company | Company[],
        filters: Filters,
        startDate: DateTime,
        endDate: DateTime,
        standard: Standard,
        frameworkItem: string,
        userData: boolean,
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filtersObject: Record<string, any> = {};
        if (userData) {
            filtersObject['categoryUri'] = 'owner/' + this.userService.getCurrent().email;
        } else {
            filtersObject['startSourceRankPercentile'] = filters.startSourceRankPercentile;
            filtersObject['endSourceRankPercentile'] = filters.endSourceRankPercentile;
        }
        const query: Record<string, any> = {
            $query: {
                $and: [
                    {
                        conceptUri: Array.isArray(company)
                            ? { $or: company.map(({ uri }) => uri) }
                            : company.uri,
                    },
                    {
                        dateStart: formatDate(startDate),
                        dateEnd: formatDate(endDate),
                    },
                ],
            },
            $filter: filtersObject,
        };
        switch (standard) {
            case Standard.ESG:
                query['$query'].$and.push({
                    esgUri: frameworkItem,
                });
                break;
            case Standard.SDG:
                query['$query'].$and.push({
                    sdgUri: frameworkItem,
                });
                break;
            case Standard.SASB:
                query['$query'].$and.push({
                    sasbUri: frameworkItem,
                });
                break;
        }
        return this.backendService
            .post$<{ timeAggr: ServerResponse<TimeAggregation[]> }>(
                userData ? this.eventTypeUserUrl : this.eventTypeUrl,
                {
                    query: JSON.stringify(query),
                    mentionsConceptSyn: ['isin', 'ticker'],
                    resultType: 'timeAggr',
                },
            )
            .pipe(
                map((response) => response.body!),
                map((response) => {
                    if (response && !('error' in response)) {
                        return response;
                    }
                    return {
                        timeAggr: {
                            results: [],
                            totalResults: 0,
                            usedResults: 0,
                        },
                    };
                }),
                map((response) => {
                    return response.timeAggr;
                }),
            );
    }
    public getTimelines(
        list: Observable<TaxonomyItem[]>,
        company: Company | Company[],
        filters: Filters,
        dateRange: DateRange,
        standard: Standard,
        userData: boolean,
    ) {
        return list.pipe(
            switchMap((list) => {
                return forkJoin(
                    list.map((item) => {
                        return this.getTimeline(
                            company,
                            filters,
                            dateRange.start,
                            dateRange.end,
                            standard,
                            item.uri,
                            userData,
                        ).pipe(
                            map((result) => {
                                return {
                                    ...result,
                                    ...item,
                                };
                            }),
                        );
                    }),
                );
            }),
        );
    }
    public getTaxonomyList(standard: Standard) {
        return this.backendService.get$<{ results: TaxonomyItem[] }>(this.getItemsUrl(standard)).pipe(
            map((response) => {
                if (!response.body) {
                    return [];
                }
                return response.body!.results;
            }),
            map((taxonomyList) => {
                localStorage.setItem('TaxonomyList-' + standard, JSON.stringify(taxonomyList));
                let items = [];
                for (const [index, item] of Object.entries(taxonomyList)) {
                    items.push(item);
                    // Add an OR operator after each item except the last one
                    if (Number(index) !== taxonomyList.length - 1) {
                        items.push({
                            id: 'b' + (items.length + 1),
                            label: 'OR',
                            negate: false,
                            type: 'operator',
                        });
                    }
                }
                localStorage.setItem('Standard-' + standard, JSON.stringify(items));
                return taxonomyList;
            }),
        );
    }

    private getFromLocalStorage<T = unknown>(key: string, defaultValue: T): T {
        if (this._cachedStorage.has(key)) {
            return this._cachedStorage.get(key) as T;
        } else {
            const value = localStorage.getItem(key);
            if (value) {
                const parsed = JSON.parse(localStorage.getItem(key) + '');
                this._cachedStorage.set(key, parsed);
                return parsed as T;
            } else {
                return defaultValue as T;
            }
        }
    }

    private getFromLocalStorage$<T = unknown>(key: string, defaultValue: T): T {
        return of(this.getFromLocalStorage(key, defaultValue)) as T;
    }

    private getItemsUrl(standard: Standard) {
        return `/eventType/${this.standardMap[standard]}/getItems`;
    }

    private post$<T = ER.API>(
        url: string,
        params: object = {},
        configuration: PostRequestConfiguration = { sendAsForm: false },
    ): Observable<T> {
        const { sendAsForm = false, ...config } = configuration;
        if (!(params instanceof HttpParams) && !_.isNil(params) && !_.isEmpty(params) && sendAsForm) {
            params = this.backendService.stringifyHttpParams(params);
        }
        return this.backendService.request$(url, {
            params,
            method: MethodType.POST,
            ...config,
        });
    }

    /**
     * Returns the search parameters for a given query and type.
     * @param {ER.Query} query - The search query.
     * @param {ER.Type} type - The type of search.
     * @param {number} page - The page number (default: 1).
     * @param {number} count - The number of results per page (default: 48).
     * @returns {SearchParameters} The search parameters object.
     */
    private getSearchParams(
        query: ER.Query,
        type: ER.Type,
        page: number = 1,
        count: number = 48,
    ): SearchParameters {
        const params = {
            query,
            includeArticleSentiment: true,
            includeMentionCategories: true,
            includeConceptSynonyms: true,
            mentionsConceptSyn: ['isin', 'ticker'],
        };
        if (_.isNumber(page)) {
            _.set(params, `${type}Page`, page);
        }
        if (_.isNumber(count)) {
            _.set(params, `${type}Count`, count);
        }
        return params;
    }
}
