import { InjectionToken } from '@angular/core';
import { EventFilter, HasDuplicateFilter, IsDuplicateFilter, Languages } from './types/types';

export const LANGUAGES = new InjectionToken('languages');
export const IS_DUPLICATE_FILTERS = new InjectionToken('isDuplicateFilters');
export const HAS_DUPLICATE_FILTERS = new InjectionToken('hasDuplicateFilters');
export const EVENT_FILTERS = new InjectionToken('eventFilters');

export type EventFilters = Array<
    EventFilter.KeepAll | EventFilter.SkipArticlesWithoutEvent | EventFilter.KeepOnlyArticlesWithoutEvent
>;
export type IsDuplicateFilters = Array<
    IsDuplicateFilter.KeepAll | IsDuplicateFilter.SkipDuplicates | IsDuplicateFilter.KeepOnlyDuplicates
>;
export type HasDuplicateFilters = Array<
    | HasDuplicateFilter.KeepAll
    | HasDuplicateFilter.SkipHasDuplicates
    | HasDuplicateFilter.KeepOnlyHasDuplicates
>;

export const eventFilters: EventFilters = [
    { name: 'No filter', val: 'keepAll' },
    {
        name: 'Show only articles assigned to an event',
        val: 'skipArticlesWithoutEvent',
    },
    {
        name: 'Show only articles not assigned to an event',
        val: 'keepOnlyArticlesWithoutEvent',
    },
];

export const isDuplicateFilters: IsDuplicateFilters = [
    { name: 'No filter', val: 'keepAll' },
    {
        name: 'Show only articles that are not duplicates',
        val: 'skipDuplicates',
    },
    {
        name: 'Show only articles that are duplicates',
        val: 'keepOnlyDuplicates',
    },
];

export const hasDuplicateFilters: HasDuplicateFilters = [
    { name: 'No filter', val: 'keepAll' },
    {
        name: 'Show only articles that do not have duplicates',
        val: 'skipHasDuplicates',
    },
    {
        name: 'Show only articles that have duplicates',
        val: 'keepOnlyHasDuplicates',
    },
];
export const languages: Languages = {
    eng: {
        name: 'English',
        short: 'eng',
        iso1: 'en',
        iso3: 'eng',
        forConcepts: true,
    },
    deu: {
        name: 'German',
        short: 'ger',
        iso1: 'de',
        iso3: 'deu',
        forConcepts: true,
    },
    spa: {
        name: 'Spanish',
        short: 'spa',
        iso1: 'es',
        iso3: 'spa',
        forConcepts: true,
    },
    cat: { name: 'Catalan', short: 'cat', iso1: 'ca', iso3: 'cat' },
    por: {
        name: 'Portuguese',
        short: 'por',
        iso1: 'pt',
        iso3: 'por',
        forConcepts: true,
    },
    ita: {
        name: 'Italian',
        short: 'ita',
        iso1: 'it',
        iso3: 'ita',
        forConcepts: true,
    },
    fra: {
        name: 'French',
        short: 'fra',
        iso1: 'fr',
        iso3: 'fra',
        forConcepts: true,
    },
    rus: {
        name: 'Russian',
        short: 'rus',
        iso1: 'ru',
        iso3: 'rus',
        forConcepts: true,
    },
    ara: { name: 'Arabic', short: 'ara', iso1: 'ar', iso3: 'ara' },
    tur: { name: 'Turkish', short: 'tur', iso1: 'tr', iso3: 'tur' },
    slv: {
        name: 'Slovene',
        short: 'slo',
        iso1: 'sl',
        iso3: 'slv',
        forConcepts: true,
    },
    hrv: { name: 'Croatian', short: 'cro', iso1: 'hr', iso3: 'hrv' },
    srp: { name: 'Serbian', short: 'srb', iso1: 'sr', iso3: 'srp' },
    // bos: { name: "Bosnian", short: "bos", iso1: "bs", iso3: "bos" },
    sqi: { name: 'Albanian', short: 'alb', iso1: 'sq', iso3: 'sqi' },
    mkd: { name: 'Macedonian', short: 'mkd', iso1: 'mk', iso3: 'mkd' },

    ces: { name: 'Czech', short: 'cze', iso1: 'cz', iso3: 'ces' },
    slk: { name: 'Slovak', short: 'slv', iso1: 'sk', iso3: 'slk' },
    pol: { name: 'Polish', short: 'pol', iso1: 'pl', iso3: 'pol' },
    eus: { name: 'Basque', short: 'bas', iso1: 'eu', iso3: 'eus' },
    gle: { name: 'Irish', short: 'iri', iso1: 'ga', iso3: 'gle' },
    hun: { name: 'Hungarian', short: 'hun', iso1: 'hu', iso3: 'hun' },
    nld: { name: 'Dutch', short: 'dut', iso1: 'nl', iso3: 'nld' },
    gsw: { name: 'Swiss German', short: 'swg', iso1: 'sw', iso3: 'gsw' },
    swe: { name: 'Swedish', short: 'swe', iso1: 'sv', iso3: 'swe' },
    fin: { name: 'Finnish', short: 'fin', iso1: 'fi', iso3: 'fin' },
    nob: { name: 'Norwegian', short: 'nob', iso1: 'nb', iso3: 'nob' },
    lav: { name: 'Latvian', short: 'lat', iso1: 'lv', iso3: 'lav' },
    lit: { name: 'Lithuanian', short: 'lit', iso1: 'lt', iso3: 'lit' },
    est: { name: 'Estonian', short: 'est', iso1: 'et', iso3: 'est' },
    isl: { name: 'Icelandic', short: 'ice', iso1: 'is', iso3: 'isl' },

    dan: { name: 'Danish', short: 'dan', iso1: 'da', iso3: 'dan' },
    ell: { name: 'Greek', short: 'gre', iso1: 'el', iso3: 'ell' },
    ron: { name: 'Romanian', short: 'rom', iso1: 'ro', iso3: 'ron' },
    bul: { name: 'Bulgarian', short: 'bul', iso1: 'bg', iso3: 'bul' },

    kat: { name: 'Georgian', short: 'geo', iso1: 'ka', iso3: 'kat' },
    ukr: { name: 'Ukrainian', short: 'ukr', iso1: 'uk', iso3: 'ukr' },
    bel: { name: 'Belarusian', short: 'bel', iso1: 'be', iso3: 'bel' },
    hye: { name: 'Armenian', short: 'arm', iso1: 'hy', iso3: 'hye' },
    aze: { name: 'Azerbaijani', short: 'aze', iso1: 'az', iso3: 'aze' },
    kaz: { name: 'Kazakh', short: 'kaz', iso1: 'kk', iso3: 'kaz' },
    heb: { name: 'Hebrew', short: 'heb', iso1: 'he', iso3: 'heb' },
    fas: { name: 'Persian', short: 'per', iso1: 'fa', iso3: 'fas' },
    kur: { name: 'Kurdish', short: 'kur', iso1: 'ku', iso3: 'kur' },
    ind: { name: 'Indonesian', short: 'ind', iso1: 'in', iso3: 'ind' },
    mal: { name: 'Malayalam', short: 'mal', iso1: 'ml', iso3: 'mal' },
    tha: { name: 'Thai', short: 'tha', iso1: 'th', iso3: 'tha' },
    vie: { name: 'Vietnamese', short: 'vie', iso1: 'vi', iso3: 'vie' },

    zho: { name: 'Chinese', short: 'chi', iso1: 'zh', iso3: 'zho' },
    jpn: { name: 'Japanese', short: 'jap', iso1: 'ja', iso3: 'jpn' },
    kor: { name: 'Korean', short: 'kor', iso1: 'ko', iso3: 'kor' },

    urd: { name: 'Urdu', short: 'urd', iso1: 'ur', iso3: 'urd' },
    hin: { name: 'Hindi', short: 'hin', iso1: 'hi', iso3: 'hin' },
    kan: { name: 'Kannada', short: 'kan', iso1: 'kn', iso3: 'kan' },
    tam: { name: 'Tamil', short: 'tam', iso1: 'ta', iso3: 'tam' },
    guj: { name: 'Gujarati', short: 'guj', iso1: 'gu', iso3: 'guj' },
    pan: { name: 'Punjabi', short: 'pan', iso1: 'pa', iso3: 'pan' },
    ben: { name: 'Bengali', short: 'ben', iso1: 'bn', iso3: 'ben' },
};
