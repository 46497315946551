import { Injectable, inject } from '@angular/core';
import { ER, Search } from '../types/types';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { UserService } from './user.service';
import { getDateRangeEnd, getDateRangeStart } from '../utility/utility';

interface TagGroup {
    type: 'group';
    items: Search.Condition[];
}
type NestedTags = Search.Condition | Search.Condition[] | NestedTags[];
type NestedTagsWithGroups = TagGroup | Search.Condition | Search.Condition[] | NestedTags[];
type TagsWithGroups = NestedTagsWithGroups[];
type Tags = NestedTags[];
/**
 * Represents a base class for querying a collection of tags and transforming them into query objects.
 * @implements {Iterator<ER.BaseQuery, ER.QueryObject, ER.QueryObject>}
 */
class BaseQueries implements Iterator<ER.BaseQuery, undefined, ER.QueryObject> {
    public types: string[] = [];
    private index: number = 0;
    private queryObjects: ER.QueryObject[];

    /**
     * Represents a QueryOptionsService.
     * @class - Creates a new instance of the BaseQueries class.
     * @param {Search.Condition[]} items - The array of search conditions.
     * @param {string} propertyName - The name of the property.
     * @param {string} mappedName - The mapped name.
     */
    constructor(
        readonly items: Search.Condition[],
        propertyName: string,
        mappedName: string,
    ) {
        this.queryObjects = _.compact(
            _.map(items, (item) => {
                if (!this.isTag(item)) return;

                return this.prepareItemForQuery(item, propertyName, mappedName);
            }),
        ) as ER.QueryObject[];
    }

    /**
     * Returns the next value in the iterator sequence.
     * @returns {IteratorResult<ER.QueryObject, ER.QueryObject>} - An object containing the next value and a boolean indicating if the iterator is done.
     */
    public next(): IteratorResult<ER.QueryObject, undefined> {
        if (this.index < this.queryObjects.length) {
            const value = this.queryObjects[this.index++];
            return { value, done: false };
        } else {
            return { value: undefined, done: true };
        }
    }

    /**
     * Returns the next object in the iterator.
     * @returns {ER.QueryObject} The next object in the iterator.
     */
    public nextObject(): ER.QueryObject | undefined {
        return this.next().value;
    }

    /**
     * Checks if a given tag is not an operator or parentheses.
     * @param {Search.Condition} tag - The tag to check.
     * @returns {boolean} - True if the tag is not an operator or parentheses, false otherwise.
     */
    private isTag({ type }: Search.Condition): boolean {
        return type !== 'operator' && type !== 'parentheses';
    }

    /**
     * Retrieves the label from the given item.
     * @param {Search.Condition} item - The item to retrieve the label from.
     * @returns {string} The label of the item.
     */
    private getLabel(item: Search.Condition): string {
        const label = _.get(item, 'label');
        if (!label) return '';
        return _.isObject(label) ? _.first(_.values(label))! : label;
    }

    /**
     * Prepare the intermediate objects to be used in the final output from `this.buildObject`
     */
    private prepareItemForQuery(
        tag: Search.Condition,
        propertyName: string,
        mappedName: string,
    ): ER.QueryObject {
        const { negate = false, keywordLoc, mode, ...item } = tag;
        if (_.has(item, '$or') || _.has(item, '$and')) return item as ER.QueryObject;
        const result: ER.QueryObject = {};
        const isKeyword = _.get(item, 'type') === 'keyword';
        const label = this.getLabel(item);
        const value = _.trimStart(isKeyword ? label : _.get(item, propertyName, label), '-');
        const key = (
            isKeyword ? 'keyword' : _.has(item, propertyName) ? mappedName : 'keyword'
        ) as keyof ER.QueryObject;
        result[key] = value as any;
        if (keywordLoc) {
            // @ts-ignore
            result['keywordLoc'] = keywordLoc;
        }
        // when we have the concept search mode we don't need to set the keywordSearchMode as "phrase" mode will be used by default (backward compatibility)
        // @ts-ignore
        if (mode && key === 'keyword' && mode !== 'concept') {
            // @ts-ignore
            result['keywordSearchMode'] = mode;
        }
        // @ts-ignore
        if (key === 'keyword') {
            this.types = _.uniq([...this.types, 'keywords']);
        }
        return negate ? { $not: result } : result;
    }
}

@Injectable({
    providedIn: 'root',
})
export class QueryOptionsService {
    private user = inject(UserService);

    public types: string[] = [];
    // There's an option to manually set each condition you want to pass to the build function.
    // Used where we are manually building each object.
    private query: ER.QueryOptionsArgument = {};
    /**
     * Cleans up the search options by removing empty parentheses, removing obsolete boolean conditions,
     * and adding missing boolean conditions with default values.
     * @param {Search.Options} searchOptions - The search options object to clean up.
     * @returns {Search.Options} The cleaned up search options object.
     */
    public cleanup(searchOptions: Search.Options): Search.Options {
        // Check for empty parentheses
        const emptyParenthesesIndices = this.getEmptyParenthesesIndices(
            searchOptions.common?.conditions || [],
        );
        for (const index of emptyParenthesesIndices) {
            searchOptions.common?.conditions?.splice(index, 1);
        }

        // Check for stranded boolean operators
        const obsoleteMissingIndices = this.getObsoleteBooleanIndices(searchOptions.common?.conditions || []);
        for (const index of obsoleteMissingIndices) {
            searchOptions.common?.conditions?.splice(index, 1);
        }

        // Check conditions if there are any missing boolean operators
        const indices = this.getMissingBooleanIndices(searchOptions.common?.conditions || []);
        const defaultBooleanOperatorTag: Search.Condition = {
            id: `b${_.size(searchOptions.common?.conditions)}`,
            label: 'AND',
            type: 'operator',
            negate: false,
        };
        for (const index of indices) {
            searchOptions.common?.conditions?.splice(index, 0, defaultBooleanOperatorTag);
        }

        return searchOptions;
    }

    public isCombinedQuery(query?: ER.CombinedQuery | ER.BaseQuery): query is ER.CombinedQuery {
        return _.has(query, '$and') || _.has(query, '$or');
    }

    public isBaseQuery(query?: ER.CombinedQuery | ER.BaseQuery): query is ER.BaseQuery {
        return !this.isCombinedQuery(query);
    }

    /**
     * Builds a query object based on the provided query options and configuration.
     * @param {ER.QueryOptionsArgument} [query] - The query options argument.
     * @param {ER.QueryOptionsConfig} [config] - The configuration options for the query.
     * @returns {ER.Query} - The constructed query object.
     * @throws {Error} - If the query is undefined or invalid.
     */
    public build(query?: ER.QueryOptionsArgument, config: ER.QueryOptionsConfig = {}): ER.Query {
        const {
            logOutput = true,
            addNewItem = false,
            newItem = {},
            newItemKey = '',
            type = 'mentions',
            sendAllTimeParams = false,
            validateQueryListConditions = true,
            validateConditionsAndFilters = false, // Used for article and event stream endpoints presented in the sandbox
            userData = false,
        } = config;
        this.types = [];
        if (_.isNil(query)) {
            query = this.query;
        }
        if (_.isNil(query)) {
            throw new Error(
                'Query is undefined. Set a query or pass a valid one through the function arguments.',
            );
        }
        let queryList: ER.CombinedQuery[] = [];
        // TODO: Track query types for keywords
        const conceptObject = this.buildObject(query.conditions, 'uri', 'conceptUri', 'concepts');
        if (conceptObject) queryList.push(conceptObject);

        const categoryObject = this.buildObject(query.categories, 'uri', 'categoryUri', 'categories');
        if (categoryObject) queryList.push(categoryObject);

        const eventTypesObject = this.buildObject(query.eventTypes, 'uri', 'eventTypeUri', 'eventTypes');
        if (eventTypesObject) queryList.push(eventTypesObject);

        if (query.paiEventTypes && query.paiEventTypes.length) {
            const paiEventTypesObject = {
                $or: query.paiEventTypes.map((item) => ({ eventTypeUri: item.uri })),
            };
            queryList.push(paiEventTypesObject);
            console.log(paiEventTypesObject);
        }

        const esgObject = this.buildObject(query.esgEventTypes, 'uri', 'esgUri', 'esgEventTypes');
        if (esgObject) queryList.push(esgObject);

        const sdgObject = this.buildObject(query.sdgEventTypes, 'uri', 'sdgUri', 'sdgEventTypes');
        if (sdgObject) queryList.push(sdgObject);

        const sasbObject = this.buildObject(query.sasbEventTypes, 'uri', 'sasbUri', 'sasbEventTypes');
        if (sasbObject) queryList.push(sasbObject);

        const industriesObject = this.buildObject(query.industries, 'uri', 'industryUri', 'industries');
        if (industriesObject) queryList.push(industriesObject);

        const authorsObject = this.buildObject(query.authors, 'uri', 'authorUri', 'authors');
        if (authorsObject) queryList.push(authorsObject);

        const locationsObject = this.buildObject(query.locations, 'uri', 'locationUri', 'locations');
        if (locationsObject) queryList.push(locationsObject);
        if (addNewItem) {
            const propName = _.has(newItem, 'uri') ? 'uri' : 'wikiUri';
            const newItemObject = this.buildObject(
                [newItem as unknown as Search.Condition],
                propName,
                newItemKey,
            );
            if (newItemObject) queryList.push(newItemObject);
        }
        const consolidatedNegatedConditions = this.consolidateNegatedConditions(queryList);

        // for sources we want to put all source query items into an $or list, except the items that have a negate
        const orSourceQuery: ER.CombinedQuery[] = [];
        _.each(query.sources, (sourceItem) => {
            if (sourceItem.type === 'operator') {
                return;
            }
            const retObj = {};
            // @ts-ignore
            retObj[sourceItem['queryType']] = sourceItem['uri'] || sourceItem['wikiUri'];
            // add the condition to the negative or positive list
            if (sourceItem.negate) {
                const result = {};
                _.set(result, '$not.$or', retObj);
                consolidatedNegatedConditions.push(result);
            } else {
                orSourceQuery.push(retObj);
            }
        });
        // if we had any of the source types set, then add them to the query list
        if (orSourceQuery.length > 0) {
            if (orSourceQuery.length === 1) {
                queryList.push(orSourceQuery[0] as ER.CombinedQuery);
            } else {
                queryList.push({ $or: orSourceQuery });
            }
        }
        // filter out also the empty query items
        queryList = _.reject(queryList, (queryItem) => _.isEmpty(queryItem));

        const andQuery: Record<string, unknown> = {};
        const $filter: Record<string, unknown> = {};

        if (
            _.isUndefined(query.forceMaxDataTimeWindow) ||
            parseInt(query.forceMaxDataTimeWindow) === 0 ||
            parseInt(query.forceMaxDataTimeWindow) === 7 ||
            parseInt(query.forceMaxDataTimeWindow) === 365 ||
            sendAllTimeParams
        ) {
            // TODO: temporary fix, until issue with server removing old data is fixed
            if (!query['dateStart']) {
                query['dateStart'] = getDateRangeStart({
                    dateStart: query.dateStart,
                    forceMaxDataTimeWindow: query.forceMaxDataTimeWindow,
                }).toFormat('y-MM-dd');
            }
            if (query.dateStart) {
                andQuery['dateStart'] = query.dateStart;
            }
            if (query.dateEnd) {
                andQuery['dateEnd'] = query.dateEnd;
            }
            if (query.dateStart || query.dateEnd) {
                this.types.push('timeLimit');
            }
        }

        if (query.factLevel) {
            andQuery['factLevel'] = query.factLevel;
        }

        if (
            (!_.isUndefined(query.forceMaxDataTimeWindow) &&
                parseInt(query.forceMaxDataTimeWindow) > 7 &&
                parseInt(query.forceMaxDataTimeWindow) !== 365) ||
            sendAllTimeParams
        ) {
            _.set($filter, 'forceMaxDataTimeWindow', query.forceMaxDataTimeWindow);
            this.types.push('forceMaxDataTimeWindow');
        }

        // if not all languages are checked then add the condition for language
        if (!_.isEmpty(query.lang)) {
            if (_.isArray(query.lang)) {
                if (_.size(query.lang) === 1) {
                    andQuery['lang'] = _.first(query.lang);
                } else {
                    queryList.push({
                        $or: _.map(query.lang, (lang) => ({ lang })),
                    });
                }
            } else {
                andQuery['lang'] = query.lang;
            }
            this.types.push('langLimit');
        }
        // Leaving minArticleCount in the query for articles causes the query to fail, even though the conditions are valid
        if (query.minArticleCount && query.minArticleCount > 0 && (type === 'events' || !type)) {
            andQuery['minArticlesInEvent'] = query.minArticleCount;
            this.types.push('articleLimit');
        }

        // if any of the conditions specified then add the query to the and list
        if (!_.isEmpty(andQuery)) {
            queryList.push(andQuery);
        }
        if (_.isEmpty(queryList) && _.has($filter, 'forceMaxDataTimeWindow')) {
            if (query.dateStart) {
                andQuery['dateStart'] = query.dateStart;
            }
            if (query.dateEnd) {
                andQuery['dateEnd'] = query.dateEnd;
            }
            queryList.push(andQuery);
        }

        if (_.isEmpty(queryList) && validateQueryListConditions) {
            return {
                error: 'Please specify at least one positive search condition before making the search',
            };
        }

        if (query.eventFilter) {
            _.set($filter, 'hasEvent', query.eventFilter);
            this.types.push('eventFilter');
        }
        if (type === 'mentions') {
            _.set(
                $filter,
                'showDuplicates',
                _.isNil(query.showDuplicates) ? true : query.showDuplicates + '' === 'true',
            );
        }
        if (query.dataType) {
            _.set($filter, 'dataType', query.dataType);
            this.types.push('dataType');
        }
        if (!_.isNil(query.minSentenceIndex) && query.minSentenceIndex !== '0') {
            _.set($filter, 'minSentenceIndex', parseInt(query.minSentenceIndex));
        }

        if (!_.isNil(query.maxSentenceIndex) && query.maxSentenceIndex !== 'Unlimited') {
            _.set($filter, 'maxSentenceIndex', parseInt(query.maxSentenceIndex));
        }

        if (_.parseInt((query?.percentile ?? 100) + '') < 100) {
            _.set($filter, 'startSourceRankPercentile', 0);
            _.set($filter, 'endSourceRankPercentile', _.parseInt(query.percentile + ''));
            this.types.push('percentile');
        }

        query.percentileRange = query?.percentileRange ?? [0, _.parseInt((query.percentile ?? 100) + '')];

        if ((query?.percentileRange[0] ?? 0) > 0 || (query?.percentileRange[1] ?? 100) < 100) {
            _.set($filter, 'startSourceRankPercentile', _.parseInt(query.percentileRange[0] + ''));
            _.set($filter, 'endSourceRankPercentile', _.parseInt(query.percentileRange[1] + ''));
            this.types.push('percentile');
        }

        if (
            parseFloat((query?.minSentiment ?? -1) + '') > -1 ||
            parseFloat((query?.maxSentiment ?? 1) + '') < 1
        ) {
            _.set($filter, 'minSentiment', parseFloat(query.minSentiment + ''));
            _.set($filter, 'maxSentiment', parseFloat(query.maxSentiment + ''));
            this.types.push('sentiment');
        }

        if (query.isDuplicateFilter) {
            _.set($filter, 'isDuplicate', query.isDuplicateFilter);
            this.types.push('isDuplicateFilter');
        }
        if (query.hasDuplicateFilter) {
            _.set($filter, 'hasDuplicate', query.hasDuplicateFilter);
            this.types.push('hasDuplicateFilter');
        }
        if (query.minSocialScore) {
            _.set($filter, 'minSocialScore', parseInt(query.minSocialScore));
            this.types.push('minSocialScore');
        }

        if (userData && this.user) {
            _.set($filter, 'categoryUri', 'owner/' + this.user.getCurrent().email);
        }

        if (_.isEmpty(queryList) && validateConditionsAndFilters) {
            if (_.isEmpty($filter)) return {};
            return {
                error: 'Please specify at least one positive search condition before making the search',
            };
        }

        const $query = this.createQueryObj(queryList, consolidatedNegatedConditions);

        const constructedQuery = _.omitBy({ $query, $filter }, (value) => _.isEmpty(value)) as ER.Query;
        const validatedQuery = this.validate(constructedQuery, query);
        if (logOutput) {
            // this.logger.info(JSON.stringify(validatedQuery, null, 2), {
            //     logCallerName: false,
            //     logTime: false,
            // });
        }
        return validatedQuery;
    }
    /**
     * Remove negated conditions from the query list, apply this only on conditions that don't have parenthesess.
     * This currently applies only on the first set of conditions, that's why we skip the first one.
     * @param {ER.CombinedQuery[]} queryList - The list of query objects to remove negated conditions from.
     * @returns {ER.CombinedQuery[]} - The list of query objects with negated conditions removed.
     */
    private consolidateNegatedConditions(queryList: ER.CombinedQuery[]): ER.CombinedQuery[] {
        const negatedConditions: Set<ER.CombinedQuery> = new Set<ER.CombinedQuery>();
        queryList = _.compact(queryList);
        for (const combinedQuery of queryList) {
            if (_.has(combinedQuery, '$not')) {
                negatedConditions.add(
                    // @ts-ignore
                    (combinedQuery.$not['$or'] ?? combinedQuery.$not) as ER.CombinedQuery,
                );
                _.unset(combinedQuery, '$not');
            }
        }
        return [...negatedConditions];
    }
    /**
     * Create query object, apply consolidated negated conditions if as an additional property on the first level.
     * @param {ER.CombinedQuery[]} queryList - The list of query objects to create a query object from.
     * @param {ER.CombinedQuery[]} negated - The list of negated conditions to apply to the query object.
     * @returns {ER.CombinedQuery} - The created query object.
     */
    private createQueryObj(queryList: ER.CombinedQuery[], negated: ER.CombinedQuery[]): ER.CombinedQuery {
        const negatedSize = _.size(_.flatten(negated));
        if (_.size(queryList) + negatedSize === 1) {
            return queryList[0];
        }
        let conditions: ER.CombinedQuery = {};
        // Loop through underlying items have a property with an array of length 1 then extract that item
        conditions.$and = _.flatten(
            _.map(queryList, (item) => {
                const values = _.first(_.values(item));
                if (_.isArray(values) && _.size(values) === 1) {
                    return _.first(values);
                } else if (_.has(item, '$and')) {
                    return values;
                } else {
                    return item;
                }
            }),
        ) as (ER.CombinedQuery | ER.BaseQuery)[];
        if (_.size(conditions.$and) === 1) {
            conditions = _.first(conditions.$and)!;
        }
        if (negatedSize !== 0) {
            // @ts-ignore
            negated = _.map(_.flatten(negated), (item) =>
                _.get(item, '$not.$or', item),
            ) as ER.CombinedQuery[];
            conditions.$not = negatedSize === 1 ? _.first(negated) : { $or: negated };
        }
        return conditions;
    }
    /**
     * Validates the constructed query and query options to ensure they meet certain criteria.
     * @param {ER.Query} constructedQuery - The constructed query object.
     * @param {ER.QueryOptionsArgument} query - The query options object.
     * @returns {ER.Query} - The validated query object.
     */
    public validate(constructedQuery: ER.Query, query: ER.QueryOptionsArgument): ER.Query {
        // Validate dateStart and dateEnd
        if (_.has(query, 'dateStart') && !!query.dateStart && _.has(query, 'dateEnd') && !!query.dateEnd) {
            const dateStart = DateTime.fromFormat(query.dateStart, 'y-M-d');
            const dateEnd = DateTime.fromFormat(query.dateEnd, 'y-M-d');
            if (dateEnd < dateStart) {
                return {
                    ...constructedQuery,
                    error: 'The ending date you entered is before the starting date',
                };
            }
        }
        if (_.has(query, 'conditions')) {
            const countBrackets = _.filter(query.conditions, ({ type }) => type === 'parentheses');
            if (_.size(countBrackets) % 2 !== 0) {
                return {
                    ...constructedQuery,
                    error: "Search conditions don't have matching parentheses",
                };
            }
        }
        if (_.has(query, 'categories')) {
            const countBrackets = _.filter(query.categories, ({ type }) => type === 'parentheses');
            if (_.size(countBrackets) % 2 !== 0) {
                return {
                    ...constructedQuery,
                    error: "Categories don't have matching parentheses",
                };
            }
        }
        if (_.has(query, 'locations')) {
            const countBrackets = _.filter(query.locations, ({ type }) => type === 'parentheses');
            if (_.size(countBrackets) % 2 !== 0) {
                return {
                    ...constructedQuery,
                    error: "Locations don't have matching parentheses",
                };
            }
        }
        return constructedQuery;
    }
    /**
     * Build a query object from the given conditions
     * @param {Search.Condition[]} items conditions gathered from TagInput like component
     * @param {string} propertyName can either be a function or a string, used to identify the property that'll be used for generating the output
     * @param {string} mappedName can either be a function or a string, used as a property name of the output
     * @param {string} objectType type of object for tracking purposes
     * @returns {ER.CombinedQuery | undefined} - The combined query object.
     */
    private buildObject(
        items: Search.Condition[] | undefined,
        propertyName: string,
        mappedName: string,
        objectType?: string,
    ): ER.CombinedQuery | undefined {
        if (_.isNil(items)) return;
        try {
            // First we traverse through our supplied conditions and reformat them to be used in the final output
            const queries = new BaseQueries(items, propertyName, mappedName);
            // Then we extract the groups from the conditions based on the parentheses
            let tags = this.extractGroupsFromSearchConditions(items);
            // Then we create nested groups based on boolean precedence
            tags = this.createNestedGroupsByBooleanPrecedence(tags);
            // We add all detected types to the types array
            this.types = [...this.types, ...queries.types];
            if (objectType) {
                this.types = [...this.types, objectType];
            }
            // Then we build the query object from the extracted conditions
            const query = this.buildWithExtractedConditions(tags, queries);
            return this.flattenQueryObject(query);
        } catch (error) {
            // this.toaster.error((error as Error).message);
            return {};
        }
    }
    /**
     * Recursively flattens a combined query object by merging nested objects with the same operator and size of 1
     * into a single object and removing the operator key.
     * @param {ER.CombinedQuery} query - The combined query object to flatten.
     * @returns {ER.CombinedQuery} - The flattened combined query object.
     */
    private flattenQueryObject(query: ER.CombinedQuery): ER.CombinedQuery {
        const operators = _.filter(
            _.keys(query),
            (operator) => operator === '$and' || operator === '$or',
        ) as (keyof ER.CombinedQuery)[];
        for (const operator of operators) {
            if (_.size(query[operator] as (ER.CombinedQuery | ER.BaseQuery)[]) === 1) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                const nestedObj = _.cloneDeep(
                    _.first(query[operator] as (ER.CombinedQuery | ER.BaseQuery)[]),
                ) as ER.CombinedQuery | ER.BaseQuery;
                query = _.omit(query, operator);
                query = { ...nestedObj, ...query };
            } else {
                query[operator] = _.map(query[operator] as (ER.CombinedQuery | ER.BaseQuery)[], (item) =>
                    this.flattenQueryObject(item),
                );
            }
        }
        return query;
    }
    /**
     * Builds a combined query object with extracted conditions.
     * @param {Tags} conditions - The conditions to build the query from.
     * @param {BaseQueries} queries - The base queries to use for building the query.
     * @returns {ER.CombinedQuery} - The combined query object.
     */
    private buildWithExtractedConditions(conditions: Tags, queries: BaseQueries): ER.CombinedQuery {
        let entries: ER.QueryObject[] = [];
        let negatedConditions: ER.QueryObject[] = [];
        // Find first operator in the conditions
        const firstOperator = _.find(
            conditions,
            // @ts-ignore
            (condition) => condition['type'] === 'operator',
        );
        let operator: ER.BooleanOperator = _.get(firstOperator, 'label', 'AND') === 'AND' ? '$and' : '$or';
        let prevOperator: ER.BooleanOperator =
            _.get(firstOperator, 'label', 'AND') === 'AND' ? '$and' : '$or';
        let query: ER.CombinedQuery = {};
        for (const condition of conditions) {
            if (_.isArray(condition)) {
                entries = [...entries, this.buildWithExtractedConditions(condition, queries)];
            } else {
                if (condition.negate) {
                    const value = _.get(queries.nextObject(), '$not', {} as ER.QueryObject);
                    if (!_.isEmpty(value)) {
                        negatedConditions = [...negatedConditions, value];
                    }
                } else if (condition.type === 'operator') {
                    prevOperator = operator;
                    operator = condition.label === 'AND' ? '$and' : '$or';
                } else {
                    // @ts-ignore
                    entries = [...entries, queries.nextObject()];
                    if (operator) {
                        this.addToQueryWithOperator(query, operator, prevOperator, entries);
                        entries = [];
                    }
                }
            }
        }
        query = this.addToQueryWithOperator(query, operator, prevOperator, entries);
        query = this.applyNegatedConditions(query, negatedConditions);
        return query;
    }
    /**
     * Applies negated conditions to the given query object.
     * @param {ER.CombinedQuery} query - The query object to apply negated conditions to.
     * @param {ER.QueryObject[]} [negatedConditions] - An array of negated conditions to apply.
     * @returns {ER.CombinedQuery} The modified query object with negated conditions applied.
     */
    private applyNegatedConditions(
        query: ER.CombinedQuery,
        negatedConditions: ER.QueryObject[] = [],
    ): ER.CombinedQuery {
        // Go through the query conditions and if there is one condition with only $not then apply it to the nearest $and
        const conditions = _.flatten(_.values(query)) as (ER.BaseQuery | ER.CombinedQuery)[];
        /**
         * Due to the nature of how the query is built, if there are nested groups with all negated conditions, there is a chance that
         * they will be present in the query object as a single negated condition. We need to extract them and merge them with the existing ones.
         * @param {ER.CombinedQuery} condition - The condition to check if it is a single negated condition.
         * @returns {boolean} - True if the condition is a single negated condition, false otherwise.
         */
        const isSingleNegatedCondition = (condition: ER.CombinedQuery): boolean =>
            _.has(condition, '$not') && _.size(_.keys(condition)) === 1;
        // Determine if there are already any single negated conditions
        const areThereAnySingleNotConditions = _.some(conditions, (condition) =>
            isSingleNegatedCondition(condition),
        );
        // If there are and and query has "and" operator then merge the negated conditions with the existing ones
        if (areThereAnySingleNotConditions && _.has(query, '$and')) {
            const negatedConditions = _.filter(conditions, (condition) =>
                isSingleNegatedCondition(condition),
            );
            // Merge the negated conditions
            const mergedNegatedConditions = _.reduce(
                negatedConditions,
                (result, condition) => {
                    const $not = _.get(condition, '$not.$or', [_.get(condition, '$not', {})]);
                    return [...result, ...$not];
                },
                [] as (ER.BaseQuery | ER.CombinedQuery)[],
            );
            // Remove the negated conditions from the query
            const filteredConditions = conditions.filter((condition) => !isSingleNegatedCondition(condition));
            if (_.isEmpty(filteredConditions)) {
                query = _.omit(query, '$and');
            } else {
                query.$and = filteredConditions;
            }
            // Add the negated conditions to the query
            if (_.size(mergedNegatedConditions) > 1) {
                query.$not = { $or: mergedNegatedConditions };
            } else {
                query.$not = mergedNegatedConditions[0];
            }
        } else if (areThereAnySingleNotConditions && _.has(query, '$or')) {
            throw new Error('A negative condition can only be next to an AND condition');
        }
        // Add negated conditions as a query object with nested $or if there is more than one condition
        if (!_.isEmpty(negatedConditions)) {
            if (_.has(query, '$or')) {
                throw new Error('A negative condition can only be next to an AND condition');
            }
            if (_.size(negatedConditions) > 1) {
                query.$not = { $or: negatedConditions };
            } else {
                query.$not = negatedConditions[0];
            }
            negatedConditions = [];
        }
        return query;
    }
    /**
     * Adds entries to a combined query object with the specified operator.
     * @param {ER.CombinedQuery} query - The combined query object to add entries to.
     * @param {ER.BooleanOperator} operator - The operator to use for combining the entries.
     * @param {ER.BooleanOperator} prevOperator - The previous operator used in the query.
     * @param {ER.QueryObject[]} entries - The entries to add to the query.
     * @returns {ER.CombinedQuery} The updated combined query object.
     */
    private addToQueryWithOperator(
        query: ER.CombinedQuery,
        operator: ER.BooleanOperator,
        prevOperator: ER.BooleanOperator,
        entries: ER.QueryObject[],
    ): ER.CombinedQuery {
        if (_.isEmpty(_.compact(entries))) return query;
        // Do some cleanup on the entries, simplify if there is only one value, underneath one type of operator
        entries = _.map(entries, (entry) => {
            const keys = _.keys(entry);
            if (
                _.size(keys) === 1 &&
                (keys[0] === '$and' || keys[0] === '$or') &&
                _.size(_.first(_.values(entry))) === 1
            ) {
                return _.first(_.first(_.values(entry)) as [ER.BaseQuery]) as unknown as ER.BaseQuery;
            } else {
                return entry;
            }
        });
        if (operator !== prevOperator && !_.isEmpty(query ?? {})) {
            query[operator] = [_.cloneDeep(query), ...entries];
            _.unset(query, prevOperator);
        } else {
            const prevItems = _.compact(
                (_.isArray(query[operator])
                    ? _.get(query, operator, [])
                    : [_.get(query, operator)]) as ER.QueryObject[],
            );
            query[operator] = [...prevItems, ...entries];
        }
        return query;
    }
    /**
     * Extracts groups defined by parentheses from the provided array of tags.
     * @param {Search.Condition[]} items - The array of tags to extract groups from.
     * @returns {Tags} - An array of tags with extracted groups.
     * @throws {Error} - If there are unclosed parentheses or unexpected closing parentheses.
     */
    private extractGroupsFromSearchConditions(items: Search.Condition[]): Tags {
        let result: Tags = [];
        let stack: TagsWithGroups = [];
        let current: TagGroup;
        // Count open parentheses, to ensure that we don't add items to the stack before we have the final parentheses
        let count = 0;
        for (const item of items) {
            if (item.type === 'parentheses' && item.label === '(') {
                count++;
                // @ts-ignore
                if (current) {
                    const currentItems = _.get(current, 'items', []) as Search.Condition[];
                    _.set(current, 'items', [...currentItems, item]);
                } else {
                    current = {
                        type: 'group',
                        items: [],
                    };
                }
            } else if (item.type === 'parentheses' && item.label === ')') {
                count--;
                if (count === 0) {
                    // @ts-ignore
                    stack = [...stack, current];
                    // @ts-ignore
                    current = undefined;
                    // @ts-ignore
                } else if (current) {
                    const currentItems = _.get(current, 'items', []) as Search.Condition[];
                    _.set(current, 'items', [...currentItems, item]);
                } else {
                    throw new Error('Unexpected closing parentheses');
                }
            } else {
                // @ts-ignore
                if (current) {
                    const currentItems = _.get(current, 'items', []) as Search.Condition[];
                    _.set(current, 'items', [...currentItems, item]);
                } else {
                    stack = [...stack, item];
                }
            }
        }

        // @ts-ignore
        if (current) {
            throw new Error('Unclosed parentheses');
        }

        for (const item of stack) {
            if (this.isGroup(item)) {
                result = [...result, this.extractGroupsFromSearchConditions(item.items)];
            } else {
                result = [...result, item];
            }
        }

        return result;
    }
    /**
     * Checks if the given item is a TagGroup.
     * @param {NestedTagsWithGroups} item - The item to check.
     * @returns {boolean} True if the item is a TagGroup, false otherwise.
     */
    private isGroup(item: NestedTagsWithGroups): item is TagGroup {
        return _.get(item, 'type') === 'group';
    }
    /**
     * Creates nested groups of tags based on boolean precedence.
     * @param {Tags} tags - The array of tags to create nested groups from.
     * @returns {Tags} - The nested groups of tags.
     */
    private createNestedGroupsByBooleanPrecedence(tags: Tags): Tags {
        let output: Tags = [];
        let stack: Tags = [];
        let operator: ER.BooleanOperator | null = null;
        for (const [index, tag] of _.entries(tags)) {
            const numIndex = parseInt(index);
            if (_.isArray(tag)) {
                // At this point tags can already be nested due to parentheses
                stack = [...stack, this.createNestedGroupsByBooleanPrecedence(tag)];
            } else {
                if (tag.type === 'operator') {
                    // Detect if the current item is an OR operator and the previous was an AND operator
                    // In that case add the stack to the output, reset the stack and add the OR operator to the output
                    if (tag.label === 'OR' && operator === '$and') {
                        output = [...output, stack];
                        stack = [];
                        output = [...output, tag];
                    } else {
                        stack = [...stack, tag];
                    }
                    operator = tag.label === 'AND' ? '$and' : '$or';
                } else {
                    // If the next item is an AND operator and previous was OR, then push the stack into the output
                    if (this.isBooleanOperator(tags[numIndex + 1])) {
                        const next = tags[numIndex + 1] as Search.Condition;
                        if (next.label === 'AND' && operator === '$or') {
                            // If the last item was an operator then we don't create a group
                            if (this.isBooleanOperator(_.last(stack)!)) {
                                output = [...output, ...stack];
                            } else if (!_.isEmpty(stack)) {
                                output = [...output, stack];
                            }
                            stack = [];
                        }
                    }
                    stack = [...stack, tag];
                }
            }
        }
        if (_.size(stack) === 1) {
            output = [...output, ...stack];
        } else {
            output = [...output, stack];
        }
        return output;
    }
    /**
     * Checks if the given tag is a boolean operator tag.
     * @param {NestedTags} tag - The tag to check.
     * @returns {boolean} - True if the tag is a boolean operator tag, false otherwise.
     */
    private isBooleanOperator(tag: NestedTags): tag is Search.Condition {
        const { label, type } = (tag ?? {}) as Search.Condition;
        return label === 'AND' || label === 'OR' || type === 'operator';
    }
    /**
     * Returns an array of indices where empty parentheses occur in the given array of tags.
     * @param {Search.Condition[]} tags - The array of tags to search for empty parentheses.
     * @returns {number[]} An array of indices where empty parentheses occur.
     */
    private getEmptyParenthesesIndices(tags: Search.Condition[]): number[] {
        let openParenthesesCount = 0;
        return _.compact(
            _.map(tags, ({ label }, index) => {
                const previous = tags[index === 0 ? index : index - 1];
                if (label === '(') {
                    openParenthesesCount += 1;
                } else if (label === ')') {
                    openParenthesesCount -= 1;
                }
                if (previous.label === '(' && label === ')' && openParenthesesCount === 0) {
                    return index;
                }
                const next = tags[index + 1] || { type: '', label: '' };
                if (label === '(' && next.label === ')' && openParenthesesCount - 1 === 0) {
                    return index;
                }
                return undefined;
            }),
        ).sort((a, b) => b - a);
    }
    /**
     * Determine if there are any obsolete or unneeded boolean operators in the supplied tags
     * @param {Search.Condition[]} tags - The array of tags to check for obsolete boolean operators.
     * @returns {number[]} An array of indices of obsolete boolean tags.
     */
    private getObsoleteBooleanIndices(tags: Search.Condition[]): number[] {
        return _.compact(
            _.map(tags, ({ type }, index) => {
                const previous = tags[index === 0 ? index : index - 1];
                const next = tags[index + 1] || { type: '', label: '' };
                if (
                    type === 'operator' &&
                    (previous.type === 'operator' ||
                        previous.label === '(' ||
                        next.label === ')' ||
                        index + 1 >= tags.length)
                ) {
                    return index;
                }
                return undefined;
            }),
        ).sort((a, b) => b - a);
    }
    /**
     * Determine if there are any missing boolean operators in the supplied tags
     * @param {Search.Condition[]} tags - The array of tags to check for missing boolean operators.
     * @returns {number[]} An array of indices where boolean values are missing.
     */
    private getMissingBooleanIndices(tags: Search.Condition[]): number[] {
        return _.compact(
            _.map(tags, ({ label, type }, index) => {
                const previous = tags[index === 0 ? index : index - 1];
                if (
                    previous.type !== 'operator' &&
                    previous.label !== '(' &&
                    label !== ')' &&
                    type !== 'operator'
                ) {
                    return index;
                }
                return undefined;
            }),
        );
    }
}
