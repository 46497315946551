import { Injectable, inject } from '@angular/core';
import { DataType, ER, FactLevel, Lang, Search, SearchMode } from '../types/types';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { LocationService } from './location.service';

export enum ParameterType {
    TagInput,
    Value,
    Array,
}

enum TagType {
    Operator,
    Parentheses,
    Keyword,
    Wiki,
    Loc,
    Org,
    Person,
    Author,
    Other,
    Category,
    Topic,
}

enum SearchModeEnum {
    Other,
    Simple,
    Exact,
    Concept,
}
type URLValue =
    | ER.Concept[]
    | Search.Condition[]
    | (string | number)
    | Lang.ShortVal[]
    | FactLevel.Value[]
    | Search.Industry[]
    | DataType.Value[]
    | string[]
    | [number, number];
@Injectable({
    providedIn: 'root',
})
export class SearchConditionService {
    private locationService = inject(LocationService);
    private router = inject(Router);

    /**
     * This array contains original conditions and can be used for searches
     */
    private query: ER.QueryOptionsArgument = {};
    /**
     * This array contains transformed conditions
     */
    private parameters: Record<string, string | number | string[] | [number, number]> = {};
    /**
     * List of possible conditions names.
     */
    private readonly conditionNames: string[] = [
        'conditions',
        'locations',
        'sources',
        'authors',
        'categories',
        'dateStart',
        'dateEnd',
        'eventType',
        'forceMaxDataTimeWindow',
        'lang',
        'minArticleCount',
        'eventFilter',
        'isDuplicateFilter',
        'hasDuplicateFilter',
        'minSocialScore',
        'dataType',
    ];

    private readonly keywordLocations: ('title' | 'body' | 'title,body')[] = ['title', 'body', 'title,body'];

    /**
     * Clear current search conditions
     */
    public clearCurrentQuery(): void {
        this.query = {};
        this.parameters = {};
    }

    /**
     * Clear current transformed search parameters
     */
    public clearParameters(): void {
        this.router.navigate(['.'], { queryParams: {} });
    }

    /**
     * Deletes the current query parameter and the transformed parameter.
     * @param {string} keyName - the name of the parameter to delete
     */
    public deleteParameter(keyName: string): void {
        this.deleteCurrentQueryParameter(keyName);
        this.deleteTransformedParameter(keyName);
    }

    /**
     * Deletes the current query parameter from the query object.
     * @param {string} keyName - the key to delete from the query object.
     */
    public deleteCurrentQueryParameter(keyName: string): void {
        this.query = _.omitBy(this.query, (value, key) => key === keyName);
    }

    /**
     * Get current query
     * @returns {ER.QueryOptionsArgument} - the current query
     */
    public get CurrentQuery(): ER.QueryOptionsArgument {
        return this.query;
    }

    /**
     * Set current query
     */
    public set CurrentQuery(query) {
        this.query = query;
    }

    /**
     * Deletes the transformed parameter from the parameters object.
     * @param {string} keyName - the name of the parameter to delete
     */
    public deleteTransformedParameter(keyName: string): void {
        this.parameters = _.omitBy(this.parameters, (value, key) => key === keyName);
    }

    /**
     * Returns the transformed parameters
     * @returns {Record<string, string | number | string[]>} The transformed parameters
     */
    public get transformedParameters(): Record<string, string | number | string[] | [number, number]> {
        return this.parameters;
    }

    /**
     * Sets the transformed parameters
     * @param {Record<string, string | number | string[]>} params - the transformed parameters
     */
    public set transformedParameters(params: Record<string, string | number | string[]>) {
        this.parameters = params;
    }

    /**
     * Sets the value of a query parameter.
     * @param {string} keyName - the name of the query parameter to set.
     * @param {URLValue} value - the value to set the query parameter to.
     */
    public setQueryValue(keyName: string, value: URLValue): void {
        _.set(this.query, keyName, value);
    }

    /**
     * Returns true if any parameters are set.
     * @returns {boolean} - true if any parameters are set.
     */
    public anyParametersSet(): boolean {
        const getParameterValues = _.flow(
            (query) =>
                _.omitBy(
                    query,
                    (value, key) =>
                        (key === 'percentile' && value + '' === '100') ||
                        (key === 'maxSentiment' && value + '' === '1') ||
                        (key === 'minSentiment' && value + '' === '-1') ||
                        (key === 'forceMaxDataTimeWindow' && value + '' === '0'),
                ),
            (query) => _.values(query),
            (query) => _.flatten(query),
            (query) => _.compact(query),
        );
        return !_.isEmpty(getParameterValues(this.CurrentQuery));
    }

    /**
     * Transform the given value and apply it to the URL.
     * @param {string} keyName key used when applying it to the URL
     * @param {URLValue} value selected value, either a Tag, string or a number
     * @param {ParameterType} parameterType What kind of parameter are we expecting. It has 2 possible values (defaults to `ParameterType.TagInput`):
     *  - If `ParameterType.TagInput` then we are dealing with TagInput conditions
     *  - If `ParameterType.Value` then we write it directly to URL without transforms
     */
    public toURL(
        keyName: string,
        value?: URLValue,
        parameterType: ParameterType = ParameterType.TagInput,
    ): void {
        if (_.isNil(value) || (_.isArray(value) && _.isEmpty(value))) return;
        if (this.isParameterValue(value, parameterType) || this.isParameterArray(value, parameterType)) {
            this.parameters[keyName] = value;
        } else {
            this.parameters[keyName] = _.map(value, (item) => {
                const {
                    uri = '',
                    wikiUri = '',
                    type,
                    queryType,
                    negate,
                    label,
                    title,
                    mode = '',
                    name,
                    keywordLoc = '',
                } = item as Search.Condition;
                const resolvedLabel = typeof label === 'object' ? label['eng'] : label;
                const selectedUri = this.getWikiUriSnippet(uri, wikiUri);
                const selectedLabel =
                    _.minBy(_.compact([resolvedLabel, title, name]), (itemLabel) => _.size(itemLabel)) || '';
                const displayName = this.replaceAll(selectedLabel, '-', '%2D');
                const tagType = this.getTagType(type || '', queryType || '');
                switch (type) {
                    case 'parentheses':
                    case 'operator':
                        return `${TagType[_.upperFirst(type) as keyof typeof TagType]}-${displayName}`;
                    case 'keyword':
                        const keywordLocation = _.findIndex(
                            this.keywordLocations,
                            (location) => location === keywordLoc,
                        );
                        const searchMode = this.getSearchMode(mode + '');
                        return `${negate ? '-' : ''}${tagType}-${keywordLocation}-${searchMode}-${displayName}`;
                    default:
                        return `${negate ? '-' : ''}${tagType}${_.isEmpty(selectedUri) ? '' : '-' + selectedUri}-${displayName}`;
                }
            });
        }
        this.setQueryValue(keyName, value);
    }

    /**
     * Read the parameter from URL by the given name.
     * @param {string} name parameter name
     * @param {ParameterType} type parameter type. It has 2 possible values (defaults to `ParameterType.TagInput`):
     *  - If `ParameterType.TagInput` then we are dealing with TagInput conditions
     *  - If `ParameterType.Value` then we don't use any kind of transforms
     * @param {unknown} dfltVal optional default value
     * @returns {T} parameter value
     */
    public fromURL<T>(name: string, type: ParameterType = ParameterType.TagInput, dfltVal?: unknown): T {
        const parameters = _.has(this.queryParams, name)
            ? this.queryParams
            : this.locationService.queryParams;
        if (_.isNil(parameters) || _.isEmpty(parameters)) return dfltVal as T;
        const result = this.extract(name, parameters, type);
        _.set(this.query, name, result || dfltVal);
        return this.query[name as keyof ER.QueryOptionsArgument] as T;
    }

    /**
     * Construct the tag type, that'll be used when writting paramterers to the URL.
     * @param {string} type condition type
     * @param {string} queryType condition query type. Used on some components (namely SourceInput)
     * @returns {string} tag type
     */
    private getTagType(type: string, queryType: string): string {
        if (!_.isUndefined(TagType[_.upperFirst(type) as keyof typeof TagType])) {
            return `${TagType[_.upperFirst(type) as keyof typeof TagType]}`;
        } else {
            return `${TagType.Other}-${queryType || type || 'other'}`;
        }
    }

    /**
     * Determine the enum value for the given search mode
     * @param {string} mode - the search mode
     * @returns {string} the enum value of given search mode
     */
    private getSearchMode(mode: string): string {
        if (!_.isUndefined(SearchModeEnum[_.upperFirst(mode) as keyof typeof SearchModeEnum])) {
            return `${SearchModeEnum[_.upperFirst(mode) as keyof typeof SearchModeEnum]}`;
        } else {
            return `${SearchModeEnum.Other}`;
        }
    }

    /**
     * Construct the shortened URI
     * @param {string} uri condition uri
     * @param {string} wikiUri condition wiki uri. Some tags don't contain an `uri` property, only `wikiUri`.
     * @returns {string} shortened URI
     */
    private getWikiUriSnippet(uri: string, wikiUri: string): string {
        uri = _.isEmpty(uri) ? wikiUri : uri;
        const uriParts = _.split(uri, '/');
        const wiki = _.find(uriParts, (part) => _.includes(part, 'wikipedia.org'));
        if (_.isUndefined(wiki)) {
            return this.replaceAll(uri, '-', '%2D');
        } else {
            return `${_.first(_.split(wiki, '.'))}-${this.replaceAll(_.last(uriParts) || '', '-', '%2D')}`;
        }
    }

    /**
     * Extract the parameters from the given values.
     * @param {string} name name of the conditions
     * @param {{[name: string]: Search.Condition[] | (string | number)}} values object which contains the desired paramters as a property
     * @param {ParameterType} type parameter type. It has 2 possible values:
     *  - If `ParameterType.TagInput` then we are dealing with TagInput condittions
     *  - If `ParameterType.Value` then we don't use any kind of transforms
     * @returns {(string | number)[] | string | number | Search.Condition[] | undefined} extracted parameter
     */
    private extract(
        name: string,
        values: { [name: string]: Search.Condition[] | (string | number) },
        type: ParameterType,
    ): (string | number)[] | string | number | Search.Condition[] | undefined {
        if (!values[name]) return;
        if (this.isParameterTagInput(values[name], type)) {
            const parameters = _.isArray(values[name])
                ? [...(values[name] as Search.Condition[])]
                : [values[name]];
            return this.constructTagConditions(parameters, !_.isString(_.first(parameters as [])));
        } else if (this.isParameterArray(values[name], type)) {
            const result = _.get(values, name) as string | (string | number)[];
            return _.isArray(result) ? result : [result];
        } else {
            return _.get(values, name);
        }
    }

    /**
     * Type guard for the selected parameter. Checking if we are dealing with a TagInput conditions
     * @param {unknown} value parameter we are enquiring for type
     * @param {ParameterType} type current parameter type. It has 2 possible values:
     *  - If `ParameterType.TagInput` then we are dealing with TagInput condittions
     *  - If `ParameterType.Value` then we don't use any kind of transforms
     * @returns {value is Search.Condition[]} true if we are dealing with TagInput conditions
     */
    private isParameterTagInput(value: unknown, type: ParameterType): value is Search.Condition[] {
        return type === ParameterType.TagInput;
    }

    /**
     * Type guard for the selected parameter. Checking if we are dealing with simple values.
     * @param {unknown} value parameter we are enquiring for type
     * @param {ParameterType} type current parameter type. It has 2 possible values:
     *  - If `ParameterType.TagInput` then we are dealing with TagInput condittions
     *  - If `ParameterType.Value` then we don't use any kind of transforms
     * @returns {value is (string | number)} true if we are dealing with simple values
     */
    private isParameterValue(value: unknown, type: ParameterType): value is string | number {
        return type === ParameterType.Value;
    }

    /**
     * Type guard for the selected parameter. Checking if we are dealing with an array.
     * @param {unknown} value parameter we are enquiring for type
     * @param {ParameterType} type current parameter type. It has 2 possible values:
     *  - If `ParameterType.TagInput` then we are dealing with TagInput condittions
     *  - If `ParameterType.Value` then we don't use any kind of transforms
     * @returns {value is Array<(string | number)>} true if we are dealing with an array
     */
    private isParameterArray(value: unknown, type: ParameterType): value is Array<string | number> {
        return type === ParameterType.Array;
    }

    /**
     * Get parameter named query from query string in the URL.
     * @returns {{[name: string]: Search.Condition[] | (string | number)}} query parameter
     */
    private get queryParams(): {
        [name: string]: Search.Condition[] | (string | number);
    } {
        const paramsStr = _.get(this.locationService.queryParams, 'query', '');
        return paramsStr ? this.decodeQueryParams(paramsStr + '') : {};
    }

    /**
     * Decodes the query parameters from the URL.
     * @param {string} paramsStr - the query parameters as a string
     * @returns {[name: string]: Search.Condition[] | (string | number)} - the decoded query parameters
     */
    private decodeQueryParams(paramsStr: string): {
        [name: string]: Search.Condition[] | (string | number);
    } {
        try {
            return JSON.parse(decodeURI(paramsStr));
        } catch {
            return {};
        }
    }

    /**
     * Construct TagInput conditions from either transformed parameters or decoded legacy parameter
     * @param {Array<unknown>} params parameters we want to transform
     * @param {boolean} legacyMode if set to true then use the legacy mode of condition construction
     * @returns {Search.Condition[]} constructed conditions
     */
    private constructTagConditions(params: Array<unknown>, legacyMode: boolean = false): Search.Condition[] {
        let ledger: number[] = [];
        let results: Search.Condition[] = [];
        if (legacyMode) {
            results = _.map(
                params as Search.Condition[],
                ({ negate, type, label, name, title, uri, queryType, wikiUri }, id) => {
                    if (type === 'parentheses') {
                        if (label === '(') {
                            ledger = [...ledger, id];
                        } else {
                            id = _.last(ledger) || 0;
                            ledger = _.dropRight(ledger);
                        }
                    }
                    return {
                        id: id + '',
                        negate,
                        type,
                        label,
                        name: name || label,
                        title: title || label,
                        uri: uri || wikiUri,
                        queryType,
                    };
                },
            ) as Search.Condition[];
        } else {
            results = _.map(params as string[], (parameter, id) => {
                const negate = _.startsWith(parameter, '-');
                const parts = _.compact(_.split(parameter, '-'));
                const label = this.replaceAll(parts.pop() || '', '%2D', '-');
                let result: Search.Condition = {};
                switch (_.first(parts)) {
                    case '0':
                        return {
                            id: 'b' + id,
                            negate: false,
                            type: 'operator',
                            label,
                        };
                    case '1':
                        if (label === '(') {
                            ledger = [...ledger, id];
                        } else {
                            id = _.last(ledger) || 0;
                            ledger = _.dropRight(ledger);
                        }
                        return { id, negate, type: 'parentheses', label };
                    // @ts-ignore
                    case '2':
                        if (parts.length === 3) {
                            const mode = _.lowerCase(
                                SearchModeEnum[_.parseInt(parts.pop() || '')],
                            ) as SearchMode;
                            const keywordLoc = this.keywordLocations[_.parseInt(parts.pop() || '')];
                            result = { ...result, keywordLoc, mode };
                        } else {
                            const keywordLoc = this.keywordLocations[_.parseInt(parts.pop() || '')];
                            result = { ...result, keywordLoc, mode: 'concept' };
                        }

                    default:
                        const enumType = TagType[_.parseInt(parts.shift() || '')];
                        const type =
                            enumType === 'Other' && _.size(parts) !== 1
                                ? parts.shift()
                                : _.lowerFirst(enumType);
                        result = {
                            id: id + '',
                            negate,
                            type,
                            queryType: type,
                            label,
                            name: label,
                            ...result,
                        };
                        const partsSize = _.size(parts);
                        if (partsSize === 2) {
                            result['uri'] = this.replaceAll(
                                `http://${parts[partsSize - 2]}.wikipedia.org/wiki/${parts[partsSize - 1]}`,
                                '%2D',
                                '-',
                            );
                        } else if (partsSize === 1) {
                            result['uri'] = this.replaceAll(parts.pop() || '', '%2D', '-');
                        }
                        return result;
                }
            }) as Search.Condition[];
        }
        return results || [];
    }

    /**
     * Split the parameters into array of strings, and join them back with the replacement string.
     * @param {string} value - the string to replace in
     * @param {string} query - the string to replace
     * @param {string} replacement - the string to replace with
     * @returns {string} the new string with the replacements
     */
    private replaceAll(value: string, query: string, replacement: string): string {
        return _.join(_.split(value, query), replacement);
    }
}
